import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  ChevronUpIcon,
  ChevronDownIcon,
  StarFillIcon,
} from "@primer/octicons-react"
import VsCode from "images/vscode.svg"
import Github from "images/github-black.svg"
import PyCharm from "images/pycharm.svg"
import "mono-icons/iconfont/icons.css"
import { Link } from "gatsby"

export default function FAQs() {
  const [githubStars, setGithubStar] = useState(957)
  useEffect(() => {
    const fetchStars = async () => {
      const response = await fetch(
        "https://api.github.com/repos/sourcery-ai/sourcery?page=$i&per_page=100"
      )
      setGithubStar((await response.json()).stargazers_count)
    }
    fetchStars()
  })
  return (
    <div>
      <div className="grid lg:grid-cols-2 max-w-7xl mx-auto ">
        <div className="px-6 pb-6">
          <p className="font-mono text-black-300">
            man <span className="text-purple-300">sourcery</span>
          </p>
          <h3 className="font-bold text-xl lg:text-3xl">
            Frequently asked questions
          </h3>
        </div>
        <div>
          <FAQ
            question="What payment methods do you accept?"
            answer="We accept all major credit and debit cards. Your payment details will be processed securely by Stripe, a PCI Level 1 certified provider, and never touch our servers."
          />

          <FAQ
            question="How can I use Sourcery for open source projects?"
            answer="Sourcery is fully free to use on open source projects. Sourcery should work right away when working on a project that lives in a public repo - whether you're working in your IDE, in GitHub, or from the Sourcery CLI."
          />

          <FAQ
            question="How can I get access to Sourcery as a student?"
            answer="Sourcery is always free to use for students. Email us at students@sourcery.ai and we'll get you set up with a free account."
          />

          <FAQ
            question="Can I cancel the subscription anytime?"
            answer="Yes, you can cancel or downgrade anytime. You will still have access to the features in your plan until the end of the period you have paid for."
          />

          <FAQ
            question="Is there a discount for annual plans?"
            answer="Yes, annual billing is always cheaper. If you choose to pay annually you'll save 20% compared to the monthly plan."
          />
          <FAQ
            question="Is my code secure?"
            answer="We use third-party Large Language Model (LLM) providers (OpenAI). This data passes through our servers but we do not store any of your code. All LLM providers we work with do not use any of your code or messages to train their models and do not store any of your data for more than 30 days. We never store any of your code or messages."
            lastQuestion={true}
          />
        </div>
      </div>
      <div className="pt-8 lg:pt-28 items-center">
        <h3 className="font-body font-bold text-3xl text-black-500 text-center">
          User Reviews
        </h3>
        <div className="flex flex-wrap items-center justify-evenly gap-8 lg:gap-4 px-6">
          <div className="flex flex-col items-start justify-center w-[20.75rem]">
            <div className="w-8">
              <img src={VsCode} alt="user" />
            </div>
            <div>
              <p className="font-bold leading-6 mt-4">VS Code Marketplace</p>
              <div className="flex gap-4 items-center">
                <p className="text-base mt-4 text-orange-200 font-bold">
                  <StarFillIcon />
                  <StarFillIcon />
                  <StarFillIcon />
                  <StarFillIcon />
                  <StarFillIcon />
                </p>
              </div>
            </div>
            <Link to="https://marketplace.visualstudio.com/items?itemName=sourcery.sourcery&ssr=false#review-details">
              <button className="flex items-center mt-5 px-6 gap-4 py-4 font-bold bg-black-100 rounded-sm hover:bg-opacity-90">
                <p>VS Code extension</p>
                <i className="mi-external-link w-6 h-6 text-gray-500" />
              </button>
            </Link>
          </div>
          <div className="flex flex-col items-start justify-center w-[20.75rem]">
            <div className="w-8">
              <img src={PyCharm} alt="user" />
            </div>
            <div>
              <p className="font-bold leading-6 mt-4">JetBrains Marketplace</p>
              <div className="flex gap-4 items-center">
                <p className="text-base mt-4 text-orange-200 font-bold">
                  <StarFillIcon />
                  <StarFillIcon />
                  <StarFillIcon />
                  <StarFillIcon />
                  <StarFillIcon />
                </p>
              </div>
            </div>
            <Link to="https://plugins.jetbrains.com/plugin/12631-sourcery">
              <button className="flex items-center mt-5 px-6 gap-4 py-4 font-bold bg-black-100 rounded-sm hover:bg-opacity-90">
                <p>JetBrains extension</p>
                <i className="mi-external-link w-6 h-6 text-gray-500" />
              </button>
            </Link>
          </div>
          <div className="flex flex-col items-start justify-center w-[20.75rem]">
            <div className="w-8">
              <img src={Github} alt="user" />
            </div>
            <div>
              <p className="font-bold leading-6 mt-4">GitHub</p>
              <div className="flex gap-4 items-center">
                <p className="text-base mt-4">
                  <StarFillIcon className="text-orange-200" />
                  <span className="ml-4">Starred {githubStars} times</span>
                </p>
              </div>
            </div>
            <Link to="https://github.com/apps/sourcery-ai">
              <button className="flex items-center mt-5 px-6 gap-4 py-4 font-bold bg-black-100 rounded-sm hover:bg-opacity-90">
                <p>Sourcery GitHub app</p>
                <i className="mi-external-link w-6 h-6 text-gray-500" />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export function FAQ({ question, answer, lastQuestion }) {
  const [show, setShow] = useState(false)

  return (
    <div
      className="flex flex-col w-full cursor-pointer px-6"
      onClick={() => setShow(!show)}
    >
      <div className={`py-5  ${!lastQuestion && "border-b"}`}>
        <div className="flex items-center justify-between w-full">
          <span className={`text-base md:text-xl `}>{question}</span>
          <span>
            {show ? <ChevronUpIcon size={16} /> : <ChevronDownIcon size={16} />}
          </span>
        </div>
        {show ? (
          <div className="mt-6 py-4 pl-6 border-l-2 border-l-purple-200">
            <p className="text-black-500 md:max-w-4xl leading-7">{answer}</p>
          </div>
        ) : null}
      </div>
    </div>
  )
}

FAQ.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  lastQuestion: PropTypes.bool,
}
